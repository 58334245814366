import {
  validateMobileNumber,
  validateRequiredField,
  validateSelect,
} from './validator';
import { shippingFormFieldErrorType, shippingFormFieldType } from './types';
import { useState } from 'react';

export const initialValues = {
  first_name: '',
  last_name: '',
  address_line_1: '',
  address_line_2: '',
  country_code: 'none',
  city: '',
  state: 'none',
  zip_code: '',
  phone_number: '',
  // TODO: Till now there is no decision on it
  //specialOffer: false,
};

export const accountDeatilsInitialValue = {
  firstname: '',
  lastname: '',
};

export const initialErrors = {
  first_name: '',
  last_name: '',
  address: '',
  country: '',
  city: '',
  state: '',
  phone_number: '',
};

const validateShippingForm = ({
  optionalLastName,
  optionalTel,
  showNoError = false,
  optionalState = false,
}: {
  optionalLastName?: string;
  optionalTel?: string;
  showNoError?: boolean;
  optionalState?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState<boolean>(false);

  const validateForm = (
    fieldValues: shippingFormFieldType,
    setErrors: (args: shippingFormFieldErrorType) => void,
    countryCode?: string
  ) => {
    !showNoError && setLoading(true);
    const shippingFirstNameValidation = validateRequiredField(
      fieldValues.first_name
    );
    const shippingLastNameValidation = validateRequiredField(
      fieldValues?.last_name || optionalLastName || ''
    );
    const addressValidation = validateRequiredField(fieldValues.address_line_1);
    const countryValidation = validateSelect(fieldValues.country_code);
    const cityValidation = validateRequiredField(fieldValues.city);
    const stateValidation = optionalState
      ? { valid: true }
      : validateSelect(fieldValues.state);
    const phoneNumberValidation = validateMobileNumber({
      mobileNumber: fieldValues?.phone_number,
      countryCode: countryCode,
      isOptional: optionalTel,
    });

    if (
      !shippingFirstNameValidation.valid ||
      !shippingLastNameValidation.valid ||
      !addressValidation.valid ||
      !countryValidation.valid ||
      !cityValidation.valid ||
      !stateValidation.valid ||
      !phoneNumberValidation.valid
    ) {
      setValid(false);
      !showNoError &&
        setErrors({
          first_name: shippingFirstNameValidation?.messageKey || '',
          last_name: shippingLastNameValidation?.messageKey || '',
          address: addressValidation?.messageKey || '',
          country: countryValidation?.messageKey || '',
          city: cityValidation?.messageKey || '',
          state: stateValidation?.messageKey || '',
          phone_number: phoneNumberValidation?.messageKey || '',
        });
      !showNoError && setLoading(false);
      return false;
    } else {
      setValid(true);
      !showNoError && setErrors(initialErrors);
      !showNoError && setLoading(false);
      return true;
    }
  };

  return {
    validateForm,
    loading,
    valid,
  };
};

export default validateShippingForm;
