import { IValidationResult } from './types';

const MIN_NAME_LENGTH = 1;

export const validateEmail = (email?: string): IValidationResult => {
  if (!email) {
    return _invalid('Please enter an email address.');
  }

  const EMAIL_REGEX = RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );
  return EMAIL_REGEX.test(email.trim())
    ? _valid()
    : _invalid('Please enter a valid email address.');
};

//TODO: remove validateSimplePassword later also replace validateSimplePassword with validatePassword in login page

export const validateSimplePassword = (password: string): IValidationResult => {
  if (!password) {
    return _invalid('Please enter a password.');
  }
  return _valid();
  // const strongPasswordRegex = RegExp(
  //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#?]).{8,}$/
  // );

  // return password.match(strongPasswordRegex)
  //   ? _valid()
  //   : _invalid('strong password error');
};

export const validatePassword = (password: string): IValidationResult => {
  if (!password) {
    return _invalid('Please enter a password.');
  }
  // const strongPasswordRegex = RegExp(
  //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#?]).{8,}$/
  // );

  const useAtLeastEightCharacterRegex = RegExp(/^.{8,}$/);
  const useAtLeastOneUpperCaseRegex = RegExp(/^(?=.*[A-Z]).{1,}$/);
  const useMixtureOfLettersAndNumbersRegex = RegExp(
    /^(?=.*\d)(?=.*[a-z]).{1,}$/
  );
  const includeAtLeastOneSpecialCharacterRegex = RegExp(/^(?=.*[!@#?]).{1,}$/);

  const useAtLeastEightCharacter = password.match(
    useAtLeastEightCharacterRegex
  );
  const useAtLeastOneUpperCase = password.match(useAtLeastOneUpperCaseRegex);
  const useMixtureOfLettersAndNumbers = password.match(
    useMixtureOfLettersAndNumbersRegex
  );
  const includeAtLeastOneSpecialCharacter = password.match(
    includeAtLeastOneSpecialCharacterRegex
  );

  if (
    useAtLeastEightCharacter?.length &&
    useAtLeastOneUpperCase?.length &&
    useMixtureOfLettersAndNumbers?.length &&
    includeAtLeastOneSpecialCharacter?.length
  ) {
    return _valid();
  } else {
    let errorMessage = '';
    if (!useAtLeastEightCharacter) errorMessage = 'Use at least 8 characters';
    if (!useAtLeastOneUpperCase)
      errorMessage += ' Use at least one Uppercase letter';
    if (!useMixtureOfLettersAndNumbers)
      errorMessage += ' Use a mixture of letters and numbers';
    if (!includeAtLeastOneSpecialCharacter)
      errorMessage += ' Include at least one special character (! @ # ?])';

    return _invalid(errorMessage);
  }
};

export const validateRequiredField = (value: string): IValidationResult => {
  if (!value) {
    return _invalid('Required field');
  }
  return _valid();
};

export const validateSelect = (value: string): IValidationResult => {
  if (value === 'none') {
    return _invalid('Required field');
  }
  return _valid();
};

export const validateMobileNumber = ({
  mobileNumber,
  countryCode = '',
  isOptional,
}: any): IValidationResult => {
  if (isOptional && !mobileNumber) {
    return _valid();
  }
  if (!mobileNumber) {
    return _invalid('Required field');
  }
  //TODO: Add validation for valid mobile number a/c to country

  const mobileNumberRegex: any = {
    usa: RegExp(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/),
    can: RegExp(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/),
    ind: RegExp(/^[0-9]{10}$/),
  };
  const number =
    countryCode === 'usa' || countryCode === 'can'
      ? mobileNumber.replace(/\(|\)|\-|\ /g, '')
      : mobileNumber;

  const isValidNumber = countryCode
    ? number.match(mobileNumberRegex[countryCode])
    : [true];

  return isValidNumber?.length
    ? _valid()
    : _invalid(
        countryCode === 'usa'
          ? 'Not a valid (XXX) XXX XXXX number'
          : 'Not a valid XXXXXXXXXX number'
      );
};

function _valid(): IValidationResult {
  return { valid: true };
}

function _invalid(messageKey: string): IValidationResult {
  return { valid: false, messageKey };
}

//TODO: remove if tick is not required in password validation
function _invalidPassword(
  messageKey: string,
  errorCode: number[]
): IValidationResult {
  return { valid: false, messageKey, errorCode };
}
