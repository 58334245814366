import { makeVar } from '@apollo/client';
import { productType } from '@utils/types';

// for meal
export interface IProductFlatListState {
  productFlatList: { [product_id: number]: productType };
}
export const productFlatListVar = makeVar<IProductFlatListState>({
  productFlatList: {},
});

// for pantry
export interface IPantryProductFlatListState {
  pantryProductFlatList: { [product_id: number]: productType };
}
export const pantryProductFlatListVar = makeVar<IPantryProductFlatListState>({
  pantryProductFlatList: {},
});

// for allvarinatMapping

export const allvarinatMappingVar = makeVar<any>({});

// for sides
export const sidesPlanDetailVar = makeVar<any>({});

export const mealCountWithoutSides = makeVar<number>(0);
export const totalMealCountWithoutSides = makeVar<number>(0);
