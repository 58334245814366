interface ITCCLOGO {
  fill?: any;
}

const TCCLOGO = (props: ITCCLOGO) => {
  const { fill } = props || {};

  return (
    <svg
      width="37"
      height="40"
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_442_6278"
        //style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="6"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 6.71362H13.2656V19.7671H0V6.71362Z"
          fill={fill || 'white'}
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1893 12.4455C10.8362 11.1151 10.0254 9.39405 9.86196 7.54071C8.84433 6.98198 7.73809 6.71362 6.64519 6.71362C4.35013 6.71362 2.11594 7.88526 0.889704 9.97817C0.00475806 11.4884 -0.229651 13.2472 0.229704 14.9306C0.688844 16.6139 1.78691 18.0207 3.32132 18.8916C4.85573 19.7625 6.64261 19.9932 8.35358 19.5409C10.0641 19.0891 11.4936 18.0084 12.3783 16.4984C12.9527 15.5185 13.251 14.4338 13.2656 13.3329C12.8856 13.0702 12.5248 12.7752 12.1893 12.4455"
        fill={fill || '#B38552'}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0774 20.7131C9.65862 20.9084 9.22108 21.0711 8.76624 21.1942C8.06819 21.3831 7.35956 21.4766 6.65568 21.4766C5.48983 21.4766 4.33803 21.2165 3.26579 20.7126C1.75989 21.6139 0.682452 23.0459 0.229125 24.7549C-0.230903 26.4897 0.00667751 28.3012 0.897766 29.8559C2.73701 33.0649 6.82236 34.1665 10.0047 32.3118C13.1869 30.4567 14.2792 26.3364 12.44 23.1272C11.8602 22.1156 11.0476 21.2932 10.0774 20.7131"
        fill={fill || '#D56A1A'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3323 26.5461H18.3288C17.1766 26.5466 16.0701 26.8391 15.0909 27.3854C14.8872 29.7099 13.6859 31.9134 11.667 33.3213C11.6947 35.0627 12.3845 36.6956 13.6197 37.9291C14.8787 39.187 16.5525 39.8795 18.3323 39.8795H18.3357C22.0126 39.8775 25.0023 36.8855 25.0003 33.2094C24.9984 29.5345 22.0074 26.5461 18.3323 26.5461"
        fill={fill || '#F6B218'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3302 20.774C31.787 19.8841 29.9897 19.6485 28.2692 20.1105C26.5489 20.5723 25.1113 21.6759 24.2215 23.2185C23.6297 24.2441 23.3443 25.3629 23.333 26.4698C25.2259 27.805 26.5221 29.93 26.7377 32.3622C29.9132 34.1423 33.9525 33.04 35.7759 29.879C37.6128 26.6947 36.5158 22.6103 33.3302 20.774"
        fill={fill || '#B38552'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5895 19.0461C27.0117 18.8486 27.4525 18.6842 27.9111 18.5601C29.7685 18.0572 31.6989 18.2321 33.4206 19.0435C36.538 17.1651 37.5964 13.0899 35.7731 9.90807C34.8822 8.35304 33.4442 7.24134 31.724 6.77729C31.1508 6.62268 30.5689 6.54614 29.991 6.54614C28.8348 6.54614 27.6938 6.85231 26.6661 7.45155C25.1244 8.35042 24.0221 9.801 23.5621 11.5362C23.1022 13.2711 23.3396 15.0832 24.2305 16.6383C24.8205 17.6673 25.6418 18.4783 26.5895 19.0461"
        fill={fill || '#D56A1A'}
      />
      <mask
        id="mask1_442_6278"
        //style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="11"
        y="0"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7285 0H24.9885V13.0517H11.7285V0Z"
          fill={fill || 'white'}
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9885 6.42833C24.9631 4.7206 24.2767 3.11849 23.0462 1.90896C21.7941 0.677639 20.1298 -0.000244141 18.3599 -0.000244141H18.3565C16.5851 0.00060242 14.9201 0.680391 13.6683 1.91341C12.4165 3.14663 11.7277 4.78579 11.7285 6.52907C11.7294 8.27214 12.4201 9.91066 13.673 11.1426C14.9255 12.3737 16.5896 13.0516 18.3595 13.0516H18.3631C19.5143 13.051 20.6199 12.7621 21.5971 12.2222C21.8014 9.95468 22.9909 7.80547 24.9885 6.42833"
        fill={fill || '#F6B218'}
      />
    </svg>
  );
};

export default TCCLOGO;
