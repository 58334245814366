import { makeVar, useReactiveVar } from '@apollo/client';

export const promoCodeCodeVar = makeVar<string>('');
export const promoCodeDetailsVar = makeVar<any>(null);
export const isPromoCodeAutoApply = makeVar<boolean>(false);

export const updatePromoCode = (promoCode: string) => {
  promoCodeCodeVar(promoCode);
};

export const usePersistentPromoCode = () => {
  const promoCodeState = useReactiveVar(promoCodeCodeVar);

  return promoCodeState;
};

export const usePersistentPromoCodeDetails = () => {
  const promoCodeDetailsState = useReactiveVar(promoCodeDetailsVar);

  return promoCodeDetailsState;
};
