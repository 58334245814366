import { makeVar, useReactiveVar } from '@apollo/client';
import { shippingFormFieldType, TAccountDetails } from '@utils/types';
import {
  accountDeatilsInitialValue,
  initialValues,
} from '@utils/validateShippingForm';

export const shippingFormInputVar =
  makeVar<shippingFormFieldType>(initialValues);

export const accountDeatilInputVar = makeVar<TAccountDetails>(
  accountDeatilsInitialValue
);
export const updateShippingFormInput = (input: shippingFormFieldType) => {
  shippingFormInputVar(input);
};

export const usePersistentShippingFormInput = () => {
  const shippingFormInputState = useReactiveVar(shippingFormInputVar);
  return shippingFormInputState;
};
