import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
export interface cartItemState {
  [id: string]: number;
}

export const cartItemsStateVar = makeVar<cartItemState>({});
export const pantryCartItemsStateVar = makeVar<cartItemState>({});

// meal cart
export const updateCartItemCount = (productId: number, newCount: number) => {
  if (newCount === 0) {
    delete cartItemsStateVar()[productId];
    cartItemsStateVar({
      ...cartItemsStateVar(),
    });
  } else {
    cartItemsStateVar({
      ...cartItemsStateVar(),
      [productId]: newCount,
    });
  }

  localStorage.setItem('cartItems', JSON.stringify(cartItemsStateVar()));
};

export const updateCart = (productIdQuantity: any) => {
  cartItemsStateVar({ ...cartItemsStateVar(), ...productIdQuantity });

  localStorage.setItem('cartItems', JSON.stringify(cartItemsStateVar()));
};

export const removeCartCount = () => {
  cartItemsStateVar({});

  localStorage.setItem('cartItems', JSON.stringify(cartItemsStateVar()));
};

export const usePersistentCartItems = () => {
  const cartItemsState = useReactiveVar(cartItemsStateVar);
  useEffect(() => {
    const cartItems = localStorage.getItem('cartItems');
    if (cartItems) {
      cartItemsStateVar(JSON.parse(cartItems));
    }
  }, []);
  return cartItemsState;
};

// Pantry cart

export const updatePantryCartItemCount = (
  productId: number,
  newCount: number
) => {
  if (newCount === 0) {
    delete pantryCartItemsStateVar()[productId];
    pantryCartItemsStateVar({
      ...pantryCartItemsStateVar(),
    });
  } else {
    pantryCartItemsStateVar({
      ...pantryCartItemsStateVar(),
      [productId]: newCount,
    });
  }

  localStorage.setItem(
    'pantryCartItems',
    JSON.stringify(pantryCartItemsStateVar())
  );
};

export const updatePantryCart = (productIdQuantity: any) => {
  pantryCartItemsStateVar({
    ...pantryCartItemsStateVar(),
    ...productIdQuantity,
  });

  localStorage.setItem(
    'pantryCartItems',
    JSON.stringify(pantryCartItemsStateVar())
  );
};

export const removePantryCartCount = () => {
  pantryCartItemsStateVar({});

  localStorage.setItem(
    'pantryCartItems',
    JSON.stringify(pantryCartItemsStateVar())
  );
};

export const usePersistentPantryCartItems = () => {
  const pantryCartItemsState = useReactiveVar(pantryCartItemsStateVar);
  useEffect(() => {
    const cartItems = localStorage.getItem('pantryCartItems');
    if (cartItems) {
      pantryCartItemsStateVar(JSON.parse(cartItems));
    }
  }, []);
  return pantryCartItemsState;
};

export const isCartOpen = makeVar<boolean>(false);

// used when modal and drawer open at a time and on close modal drawer remain open
export const isModalOpen = makeVar<boolean>(false);
